<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Asosiy Vosita Sotish</h3>
          </div>
        </div>
        <div class="card-body">
          <v-container fluid>
            <v-row class="p-0">
              <v-col cols="4" class="pl-0 py-0">
                <v-text-field
                  :error-messages="reg_nomerErrors"
                  v-model="reg_nomer"
                  dense
                  label="Registratsiya raqami"
                  outlined
                  :success="reg_nomer !== ''"
                  @input="$v.reg_nomer.$touch()"
                  @blur="$v.reg_nomer.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :error-messages="oper_dateErrors"
                      label="Sana"
                      dense
                      append-icon="event"
                      :value="oper_date | dateFilter"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      :success="oper_date !== ''"
                      @input="$v.oper_date.$touch()"
                      @blur="$v.oper_date.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="oper_date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-select
                  @change="getExportedWarehouse(exported_warehouse.id)"
                  :error-messages="exported_warehouseErrors"
                  v-model="exported_warehouse"
                  :items="allSklads"
                  dense
                  :success="exported_warehouse !== ''"
                  item-text="sklad_name"
                  item-value="value"
                  return-object
                  outlined
                  label="Sklad"
                  @input="$v.exported_warehouse.$touch()"
                  @blur="$v.exported_warehouse.$touch()"
                ></v-select>
              </v-col>
              <v-col cols="6" class="pl-0 py-0">
                <v-autocomplete
                  :error-messages="contragentErrors"
                  :items="contragentsList"
                  v-model="contragent"
                  dense
                  @change="getContracts"
                  v-debounce:400ms="myFn"
                  item-text="full_name"
                  :success="
                    contragent !== '' &&
                    contragent !== null &&
                    contragent !== undefined
                  "
                  item-value="id"
                  label="Kontragent"
                  return-object
                  outlined
                  hide-no-data
                  @input="$v.contragent.$touch()"
                  @blur="$v.contragent.$touch()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" class="pl-0 py-0 pb-2">
                <v-combobox
                  :error-messages="contragent_contractErrors"
                  :items="contragent_contractLists"
                  v-model="contragent_contract"
                  item-text="reg_number"
                  dense
                  :success="
                    contragent_contract !== '' &&
                    contragent_contract !== null &&
                    contragent_contract !== undefined
                  "
                  item-value="id"
                  label="Kontragent Shartnomasi"
                  outlined
                  @input="$v.contragent_contract.$touch()"
                  @blur="$v.contragent_contract.$touch()"
                ></v-combobox>
              </v-col>
              <v-col cols="12" class="pl-0 pb-2">
                <v-row align="center" justify="space-between">
                  <v-col class="d-flex" cols="12" sm="12">
                    <v-autocomplete
                      v-model="mainAsset"
                      dense
                      :items="mainAssetList"
                      item-text="name"
                      label="Asosiy vosita"
                      :success="
                        mainAsset !== '' &&
                        mainAsset !== null &&
                        mainAsset !== undefined
                      "
                      outlined
                      hide-no-data
                      return-object
                      class="mx-1 w-30"
                    ></v-autocomplete>
                    <v-text-field
                      v-model="price"
                      label="Sotish summasi"
                      outlined
                      dense
                      :success="
                        price !== '' && price !== null && price !== undefined
                      "
                      v-currency="options"
                      class="mx-1 w-20"
                    ></v-text-field>
                    <v-text-field
                      v-model="nds"
                      label="NDS"
                      outlined
                      dense
                      :success="nds !== '' && nds !== 0 && nds !== undefined"
                      class="mx-1 w-20"
                    ></v-text-field>
                    <v-text-field
                      v-model="ndsSumma"
                      label="NDS summa"
                      outlined
                      dense
                      :success="
                        ndsSumma !== '' &&
                        ndsSumma !== null &&
                        ndsSumma !== undefined
                      "
                      v-currency="options"
                      class="mx-1 w-20"
                    ></v-text-field>
                    <v-text-field
                      :value="compare | mask"
                      :label="compare > 0 ? 'Foyda' : 'Zarar'"
                      :success="compare > 0"
                      :error="compare < 0"
                      dense
                      outlined
                      v-currency="options"
                      class="mx-1 w-20"
                    ></v-text-field>
                  </v-col>
                  <!-- EXP CATEGORIES -->
                  <v-col cols="3" v-if="compare < 0" class="py-0">
                    <v-autocomplete
                      @change="change1"
                      :items="mainExpenditures"
                      :success="
                        mainExp !== '' &&
                        mainExp !== null &&
                        mainExp !== undefined
                      "
                      v-model="mainExp"
                      item-text="name"
                      dense
                      return-object
                      item-value="id"
                      label="Asosiy xarajatlar"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="3" v-if="compare < 0" class="py-0">
                    <v-autocomplete
                      dense
                      @change="change2"
                      :items="category"
                      v-model="expCategory"
                      :success="
                        expCategory !== '' &&
                        expCategory !== null &&
                        expCategory !== undefined
                      "
                      item-text="name"
                      return-object
                      item-value="id"
                      no-data-text="Malumot topilmadi"
                      label="Kategoriya"
                      hide-no-data
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="3" v-if="compare < 0" class="py-0">
                    <v-combobox
                      dense
                      :items="category2"
                      v-model="expSet1"
                      :success="
                        expSet1 !== '' &&
                        expSet1 !== null &&
                        expSet1 !== undefined
                      "
                      no-data-text="Malumot topilmadi"
                      :hide-no-data="true"
                      item-text="name"
                      item-value="id"
                      label="Kategoriya2"
                      outlined
                    ></v-combobox>
                  </v-col>
                  <!-- INCOME CATEGORIES -->
                  <v-col cols="4" v-if="compare >= 0" class="py-0">
                    <v-autocomplete
                      @change="changeMainIncome"
                      :items="mainProfitSubCategory"
                      :success="
                        mainIncome !== '' &&
                        mainIncome !== null &&
                        mainIncome !== undefined
                      "
                      v-model="mainIncome"
                      item-text="name"
                      dense
                      return-object
                      item-value="id"
                      label="Asosiy Kirim"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4" v-if="compare >= 0" class="py-0">
                    <v-autocomplete
                      dense
                      @change="changeMainIncomeCategory"
                      :items="mainIncomeCategoryList"
                      v-model="mainIncomeCategory"
                      :success="
                        mainIncomeCategory !== '' &&
                        mainIncomeCategory !== null &&
                        mainIncomeCategory !== undefined
                      "
                      item-text="name"
                      return-object
                      item-value="id"
                      hide-no-data
                      label="Kategoriya"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4" v-if="compare >= 0" class="py-0">
                    <v-combobox
                      dense
                      :items="mainIncomeSubCategoryList"
                      v-model="mainIncomeSubCategory"
                      :success="
                        mainIncomeSubCategory !== '' &&
                        mainIncomeSubCategory !== null &&
                        mainIncomeSubCategory !== undefined
                      "
                      no-data-text="Malumot topilmadi"
                      :hide-no-data="true"
                      item-text="name"
                      item-value="id"
                      label="Kategoriya2"
                      outlined
                    ></v-combobox>
                  </v-col>
                </v-row>
                <div>
                  <v-row justify="end" class="text-right">
                    <v-col>
                      <v-btn @click="add" dense color="success">+</v-btn>
                    </v-col>
                  </v-row>
                </div>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  no-data-text="Malumot kiritilmagan"
                  hide-default-footer
                >
                  <template v-slot:[`item.main`]="{ item }">
                    <!-- <div v-if="item.mainExp !== '' || item.mainIncome !== ''"> -->
                    <div
                      v-if="item.mainExp !== '' && item.mainExp !== undefined"
                    >
                      {{ item.mainExp.name }}
                    </div>
                    <div
                      v-if="
                        item.mainIncome !== '' && item.mainIncome !== undefined
                      "
                    >
                      {{ item.mainIncome.name }}
                    </div>
                    <!-- </div> -->
                  </template>
                  <template v-slot:[`item.subCategory`]="{ item }">
                    <!-- <div v-if="item.mainExp !== '' || item.mainIncome !== ''"> -->
                    <div
                      v-if="item.mainExp !== '' && item.mainExp !== undefined"
                    >
                      {{ item.mainExpCategory.name }}
                    </div>
                    <div
                      v-if="
                        item.mainIncome !== '' && item.mainIncome !== undefined
                      "
                    >
                      {{ item.mainIncomeCategory.name }}
                    </div>
                    <!-- </div> -->
                  </template>
                  <template v-slot:[`item.Category`]="{ item }">
                    <!-- <div v-if="item.mainExp !== '' || item.mainIncome !== ''"> -->
                    <div
                      v-if="item.mainExp !== '' && item.mainExp !== undefined"
                    >
                      {{ item.mainExpSubCAtegory.name }}
                    </div>
                    <div
                      v-if="
                        item.mainIncome !== '' && item.mainIncome !== undefined
                      "
                    >
                      {{ item.mainIncomeSubCategory.name }}
                    </div>
                    <!-- </div> -->
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      v-if="
                        item.main_asset.id !==
                        items[items.length - 1].main_asset.id
                      "
                      icon
                      @click="remove(item)"
                    >
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" class="pl-0 py-0">
                <Factura
                  :buyer="contragent"
                  :buyer_contract="contragent_contract"
                  :reg_number="reg_nomer"
                  :oper_date="oper_date"
                  :from_warehouse="exported_warehouse"
                  :data="items"
                />
              </v-col>
              <v-col cols="12" class="pl-0 py-0">
                <v-textarea
                  dense
                  v-model="comment"
                  name="input-7-1"
                  label="Izoh"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <v-btn :disabled="sellLoading" @click="save" color="success">
                <i v-if="sellLoading" class="el-icon-loading"></i> Saqlash
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Factura from './Factura'
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { required } from 'vuelidate/lib/validators'
export default {
  components: { Factura },
  data() {
    return {
      sellLoading: false,
      test: '',
      mainData: '',
      ndsSumma: '',
      nds: 0,
      mainIncomeSubCategory: '',
      mainIncomeCategory: '',
      mainIncomeSubCategoryList: [],
      mainIncomeCategoryList: [],
      mainIncome: '',
      expSet1: '',
      category: [],
      category2: [],
      expCategory: '',
      mainExp: '',
      compare: '',
      headers: [
        { text: 'Asosiy vosita', value: 'main_asset.name' },
        { text: 'Asosiy nimadur', value: 'main' },
        { text: 'Kategoriya', value: 'Category' },
        { text: 'Sub kategoriya', value: 'subCategory' },
        { text: 'Summa', value: 'price' },
        { text: 'NDS', value: 'nds' },
        { text: 'NDS Summa', value: 'ndsSumma' },
        { text: 'Summasi', value: 'priceWithNds' },
        { text: '', value: 'action' }
      ],
      oper_date: '',
      reg_nomer: '',
      exported_warehouse: '',
      contragentsList: [],
      contragent: '',
      contragent_contractLists: [],
      contragent_contract: '',
      mainAssetList: [],
      mainAsset: '',
      items: [],
      price: '',
      selectedCurrencyOption: 1,
      currencyCode: 'USD',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999]
    }
  },
  validations: {
    oper_date: {
      required
    },
    reg_nomer: {
      required
    },
    exported_warehouse: {
      required
    },
    contragent: {
      required
    },
    contragent_contract: {
      required
    }
  },
  created() {
    this.$store.dispatch('getAllSkladList')
    this.$store.dispatch('mainExpenditures')
    this.$store.dispatch('getMainProfitSubCategorySpecial')
  },
  watch: {
    test: function (val) {
      val = val.toLocaleString()
      console.log(val)
    },
    mainExp: function (val) {
      this.category = val.expenditurecategory_set
    },
    expCategory: function (val) {
      if (val !== null) this.category2 = val.expendituretype_set
    },
    items: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        this.mainAssetList = []
        this.mainAssetList = [...this.mainAssets]
        val[val.length - 1].price = 0
        val[val.length - 1].priceWithNds = 0
        for (let index = 0; index < val.length - 1; index++) {
          val[val.length - 1].price += val[index].price
          val[val.length - 1].priceWithNds += val[index].priceWithNds
          this.mainAssetList = this.mainAssetList.filter(
            (x) => x.id !== val[index].main_asset.id
          )
        }
      }
    },
    mainAssets: function (val) {
      this.mainAssetList = []
      this.mainAssetList = [...val]
    },
    mainAsset: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        if (
          val.remain_amount_overall !== undefined ||
          val.remain_amount_overall !== null
        ) {
          this.price = val.remain_amount_overall.toLocaleString('es-US')
        }
      }
    },
    price: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        this.compare =
          this.toInt(val) -
          this.mainAsset.remain_amount_overall +
          (this.toInt(val) * this.toInt(this.nds)) / 100
        this.ndsSumma = (
          (this.toInt(val) * this.toInt(this.nds)) /
          100
        ).toLocaleString('es-US')
      } else {
        this.compare = 0 - this.mainAsset.remain_amount_overall
        this.ndsSumma = '0'
      }
    },
    nds: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        this.compare =
          this.toInt(this.price) -
          this.mainAsset.remain_amount_overall +
          (this.toInt(this.price) * this.toInt(val)) / 100
        this.ndsSumma = (
          (this.toInt(this.price) * this.toInt(val)) /
          100
        ).toLocaleString('es-US')
      } else {
        this.ndsSumma = '0'
        this.nds = '0'
      }
    },
    ndsSumma: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val))
        this.compare =
          this.toInt(this.price) +
          this.toInt(val) -
          this.mainAsset.remain_amount_overall
      else
        this.compare =
          this.toInt(this.price) - this.mainAsset.remain_amount_overall
    }
  },
  computed: {
    mainProfitSubCategory() {
      console.log(this.$store.state.requests.mainProfitSubCategorySpecial)
      return this.$store.state.requests.mainProfitSubCategorySpecial
    },
    mainExpenditures() {
      return this.$store.state.requests.mainExpenditures
    },
    mainAssets() {
      return this.$store.state.requests.findSellMainAsset
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    contragent_contractErrors() {
      const errors = []
      if (!this.$v.contragent_contract.$dirty) return errors
      !this.$v.contragent_contract.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    exported_warehouseErrors() {
      const errors = []
      if (!this.$v.exported_warehouse.$dirty) return errors
      !this.$v.exported_warehouse.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    contragentErrors() {
      const errors = []
      if (!this.$v.contragent.$dirty) return errors
      !this.$v.contragent.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    oper_dateErrors() {
      const errors = []
      if (!this.$v.oper_date.$dirty) return errors
      !this.$v.oper_date.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    reg_nomerErrors() {
      const errors = []
      if (!this.$v.reg_nomer.$dirty) return errors
      !this.$v.reg_nomer.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    allSklads() {
      const data = this.$store.state.requests.allSkladList
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(data)) {
        data.forEach((element) => {
          if (element.warehouse_responsible !== null) {
            if (element.responsible_description !== null) {
              element.sklad_name =
                element.name +
                ' | ' +
                element.responsible_description.staffpersonal_description
                  .first_name +
                ' ' +
                element.responsible_description.staffpersonal_description
                  .last_name
            }
          } else {
            element.sklad_name = element.name + ' Masul shaxs kiritilmagan'
          }
        })
        return data
      } else return data
    },
    options() {
      return {
        locale: 'es-US',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: false,
        valueAsInteger: false,
        allowNegative: false
      }
    }
  },
  filters: {
    mask: (val) => {
      if (Boolean(val) || typeof val == 'string') {
        return val.toLocaleString('es-US')
      } else return 0
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Asosiy Vosita Sotish' }])
  },
  methods: {
    changeMainIncome() {
      this.mainIncomeCategoryList = []
      this.mainIncomeCategoryList = [...this.mainIncome.incomecategory_set]
    },
    changeMainIncomeCategory() {
      this.mainIncomeSubCategoryList = []
      this.mainIncomeSubCategoryList = [
        ...this.mainIncomeCategory.incometype_set
      ]
    },
    change1() {
      this.expCategory = ''
      this.expSet1 = ''
    },
    change2() {
      this.expSet1 = ''
    },
    toInt(val) {
      if (typeof val == 'string') {
        return parseFloat(val.replace(/[ ,]/g, ''), 10)
      } else return val
    },
    getContracts() {
      if (this.contragent !== null) {
        this.$store
          .dispatch('counteragentContracts', this.contragent.id)
          .then((res) => {
            this.contragent_contractLists = []
            this.contragent_contractLists = [...res]
          })
      }
    },
    myFn(val) {
      const data = {
        name: val
      }
      this.$store.dispatch('counteragentsSearch', data).then((res) => {
        this.contragentsList = []
        this.contragentsList = [...res]
      })
    },
    getExportedWarehouse(payload) {
      this.$store.dispatch('findSellMainAsset', payload)
    },
    remove(val) {
      this.items.splice(this.items.indexOf(val), 1)
    },
    add() {
      if (
        this.mainAsset !== '' &&
        ((this.mainExp !== '' &&
          this.expCategory !== '' &&
          this.expSet1 !== '') ||
          (this.mainIncome !== '' &&
            this.mainIncomeCategory !== '' &&
            this.mainIncomeSubCategory !== ''))
      ) {
        this.items.reverse()
        if (this.items.length == 0)
          this.items.push({
            main_asset: { name: 'Umumiy summa' },
            price: 0,
            priceWithNds: 0
          })
        const data = {
          nds: this.nds,
          ndsSumma: this.ndsSumma,
          main_asset: this.mainAsset,
          mainExp: this.mainExp,
          mainExpCategory: this.expCategory,
          mainExpSubCAtegory: this.expSet1,
          mainIncome: this.mainIncome,
          mainIncomeCategory: this.mainIncomeCategory,
          mainIncomeSubCategory: this.mainIncomeSubCategory,
          price: this.toInt(this.price),
          priceWithNds: this.toInt(this.price) + this.toInt(this.ndsSumma)
        }
        this.items.push(data)
        this.items.reverse()
        console.log('this.items', this.items)
        this.nds = 0
        this.ndsSumma = ''
        this.expCategory = ''
        this.expSet1 = ''
        this.mainExp = ''
        this.mainIncomeCategory = ''
        this.mainIncomeSubCategory = ''
        this.mainIncome = ''
        this.mainAsset = ''
        this.price = '0'
      }
    },
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: 'Xato toldirilgan',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          factura_doc: {
            buyer: this.contragent.id,
            buyer_contract: this.contragent_contract.id,
            reg_number: this.reg_nomer,
            oper_date: this.oper_date,
            from_warehouse: this.exported_warehouse.id,
            comment: this.comment
          },
          realized_main_assets: []
        }
        if (this.items.length !== 0) {
          this.items.splice(-1, 1)
          this.items.forEach((x) => {
            const data2 = {
              mainassets_id: x.main_asset.id,
              amount: 1,
              price: x.price,
              price_deliver: x.price,
              nds_rate: this.toInt(x.nds),
              nds_amount: x.ndsSumma,
              price_with_nds: x.priceWithNds
            }
            if (x.mainExpSubCAtegory.id !== undefined) {
              data2.expenditure_type_id = x.mainExpSubCAtegory.id
              data2.expenditure_amount = x.price
            } else {
              data2.income_type_id = x.mainIncomeSubCategory.id
              data2.income_amount = x.price
            }
            data.realized_main_assets.push(data2)
          })
          console.log(data)
          this.sellLoading = true
          this.$store
            .dispatch('createMainAssetRealizationFacturas', data)
            .then(() => {
              this.$v.$reset()
              this.sellLoading = false
            })
            .catch((err) => {
              console.error(err)
              this.sellLoading = false
            })
        } else {
          Swal.fire({
            title: '',
            text: "Ma'lumotlar yuborilmadi!",
            icon: 'error',
            confirmButtonClass: 'btn btn-secondary'
          })
        }
      }
    }
  }
}
</script>
