<template>
  <div>
    <div class="card-body" v-if="data.length !== 0">
      <table>
        <thead>
          <tr>
            <th colspan="12" class="text-center">
              <b> СЧЁТ - ФАКТУРА </b>
            </th>
          </tr>
          <tr>
            <th colspan="12" class="text-center">
              № {{ reg_number }} от {{ oper_date }} к договорам №
              {{ buyer_contract.reg_number }} от {{ buyer_contract.reg_date }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="2" class="text-right p-0 border-0" style="width: 20%">
              <b> Поставщик: </b>
            </td>
            <td colspan="4" class="text-left border-0">
              {{ getCompanyDetails.company_name }}
            </td>
            <td colspan="2" class="text-right border-0" style="width: 20%">
              <b> Покупатель: </b>
            </td>
            <td colspan="4" class="text-left border-0">BTS</td>
          </tr>
          <tr>
            <td colspan="2" class="text-right border-0" style="width: 20%">
              <b> Адрес: </b>
            </td>
            <td colspan="4" class="text-left border-0">
              {{ getCompanyDetails.address }}
            </td>
            <td colspan="2" class="text-right border-0" style="width: 20%">
              <b> Адрес: </b>
            </td>
            <td colspan="4" class="text-left border-0">
              <div v-if="buyer.juridik_adress !== undefined">
                {{ buyer.juridik_adress }}
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="text-right border-0" style="width: 20%">
              <b> Идентификационный номер поставшика (ИНН): </b>
            </td>
            <td colspan="4" class="text-left border-0">
              {{ getCompanyDetails.company_inn }}
            </td>
            <td colspan="2" class="text-right border-0" style="width: 20%">
              <b> Идентификационный номер покупателя (ИНН): </b>
            </td>
            <td colspan="4" class="text-left border-0">
              <div v-if="buyer.inn !== undefined">
                {{ buyer.inn }}
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="text-right border-0" style="width: 20%">
              <b> Регистрационный код плателщика НДС: </b>
            </td>
            <td colspan="4" class="text-left border-0">
              {{ getCompanyDetails.company_nds }}
            </td>
            <td colspan="2" class="text-right border-0" style="width: 20%">
              <b> Регистрационный код плателщика НДС: </b>
            </td>
            <td colspan="4" class="text-left border-0">BTS</td>
          </tr>
          <tr>
            <td colspan="2" class="text-right border-0" style="width: 20%">
              <b> Расчётный счёт: </b>
            </td>
            <td colspan="4" class="text-left border-0">
              {{ getCompanyDetails.bank_account }}
            </td>
            <td colspan="2" class="text-right border-0" style="width: 20%">
              <b> Расчётный счёт: </b>
            </td>
            <td colspan="4" class="text-left border-0">BTS</td>
          </tr>
          <tr>
            <td colspan="2" class="text-right border-0" style="width: 20%">
              <b> МФО банка: </b>
            </td>
            <td colspan="4" class="text-left border-0">
              {{ getCompanyDetails.bank_mfo }}
            </td>
            <td colspan="2" class="text-right border-0" style="width: 20%">
              <b> МФО банка: </b>
            </td>
            <td colspan="4" class="text-left border-0">BTS</td>
          </tr>
        </tbody>
      </table>
      <table class="mt-4">
        <thead>
          <tr>
            <th rowspan="2" colspan="2" class="text-center">
              Наименование товаров(работ, услуг)
            </th>
            <th rowspan="2" class="text-center">Единица измерения</th>
            <th rowspan="2" class="text-center">Количество</th>
            <th rowspan="2" class="text-center">Цена</th>
            <th rowspan="2" class="text-center">Стоимость поставки</th>
            <th colspan="2" class="text-center">НДС</th>
            <th rowspan="2" class="text-center">
              Стоимость поставки с учётом НДС
            </th>
          </tr>
          <tr>
            <th>Ставка</th>
            <th>Сумма</th>
          </tr>
        </thead>
        <tbody v-if="Array.isArray(data)">
          <tr v-for="(item, index) in data" :key="index">
            <td v-if="index !== data.length - 1" colspan="2">
              {{ item.main_asset.name }}
            </td>
            <td v-if="index !== data.length - 1">штук</td>
            <td v-if="index !== data.length - 1">1</td>
            <td v-if="index !== data.length - 1">{{ item.price | mask }}</td>
            <td v-if="index !== data.length - 1">{{ item.price | mask }}</td>
            <td v-if="index !== data.length - 1">{{ item.nds }}</td>
            <td v-if="index !== data.length - 1">{{ item.ndsSumma | mask }}</td>
            <td v-if="index !== data.length - 1">
              {{ item.priceWithNds | mask }}
            </td>
          </tr>
          <tr>
            <td colspan="5" class="text-right">
              <b> Итого: </b>
            </td>
            <td>
              {{ data[data.length - 1].price | mask }}
            </td>
            <td></td>
            <td></td>
            <td>
              {{ data[data.length - 1].priceWithNds | mask }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Array,
    buyer: Object,
    buyer_contract: Object,
    reg_number: String,
    oper_date: String,
    from_warehouse: Object
  },
  data: () => ({}),
  created() {
    this.$store.dispatch('getCompanyGeneralInfo')
  },
  watch: {
    data: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Array.isArray(val)) {
        val.forEach((element) => {
          if (
            element.ndsSumma !== undefined &&
            typeof element.ndsSumma == 'string'
          )
            element.ndsSumma = parseFloat(
              element.ndsSumma.replace(/[ ,]/g, ''),
              10
            )
        })
      }
    }
  },
  computed: {
    getCompanyDetails() {
      return this.$store.state.requests.companyGeneralInfo
    }
  },
  filters: {
    mask(val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        return val.toLocaleString('es-US')
      } else return val
    },
    toFloat(val) {
      if (Boolean(val) && typeof val == 'string') {
        return parseFloat(val.replace(/[ ,]/g, ''), 10)
      } else return val
    }
  }
}
</script>
<style scoped>
p {
  margin-bottom: 0;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}
table td {
  text-align: center;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 10px;
}
</style>
